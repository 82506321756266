<template>
	<!-- nav -->
	<nav class="nav nav--fit">
		<div class="nav__wrap">
			<a href="#" class="nav__btn nav__btn--prev">
				<i class="icon icon icon-left">{{t('10030')}}</i>
			</a>
		</div>
	</nav>
	<!-- //nav -->
	<div id="container" class="container">
		<!-- [REST] 전체 내용 관리자 페이지에서 설정 -->
		<div id="sub" class="sub find">
			<!-- contact -->
			<section class="content">
				<div class="row">
					<div class="inner">
						<div class="inner__top">
							<h2 class="title">
								{{t('10422')}}
							</h2>
							<div class="inner__text">
								{{t('10423')}}
							</div>
						</div>
						<div class="inner__wrap"  v-for="snsuserList in userList" :key="snsuserList.mem_id" style="margin-bottom:15px;">
							<div class="dormant__auth">
								<label>
									<div class="radio">
										<input type="radio" class="rad blind" name="isDormant" v-model="multi_id" :value="snsuserList.mem_id">
										<span class="radio__bg"></span>
										<span class="dormant__authtype" style="font-size:16px;">
											{{snsuserList.mem_id}}
										</span>
									</div>
								</label>
							</div>
						</div>
					</div>
				</div>
			</section>
			<!-- //content -->
		</div>
	</div>
	<div id="navigation" class="navigation navigation--noborder">
		<div class="btns btns--centered">
			<button type="button" class="btn btn-confirm"  @click="handleOpenSns_submit">{{t('10424')}}</button>
		</div>
	</div>
	<Sns v-if="Sns_submit" :handleClose="handleCloseSns_submit" :handleClick="verify"/>
	<ErrorAlert v-if="openErrorAlert" :type="alert_msg" :handleClose="handleCloseErrorAlert" :handleClick="handleClickAlert"/>
	<TheNavigation></TheNavigation>
</template>

<script>
import { useStore,} from "vuex"
import { onMounted, ref ,computed  } from "vue"
import { useRouter } from 'vue-router'
import Sns from "@/components/layers/Message_sns";
import ErrorAlert from "@/components/layers/Message_alert";
import { useI18n } from 'vue-i18n' //번역필수 모듈
import i18n from "@/i18n"; 
export default {
	components: {		
		ErrorAlert,
		Sns
	},
	setup: function(){
    	const router = useRouter();
		const store = useStore();
        const userList = ref([]);
		const { t }= useI18n() //번역필수 모듈
        const multi_id = ref('');
		const openErrorAlert = ref(false)
		const userData = computed(() => store.state.userData);
		const Sns_submit = ref(false)
        onMounted(() =>{
			store.dispatch("mmb01/fetchMmb01", {
				hp_no:userData.value.hp_no
			}).then(x => {
        		if(x.Res_cd === "9991"){
                    userList.value.push(x)
					userList.value = userList.value[0].Res_data
					//console.log(userList.value)
        		}
        		else{					
					store.commit("app/SET_RESMESSAGE", {res_message: null});
					store.commit("app/SET_RESMESSAGE", {res_message: '다중계정 사용자가 아닙니다.'});
					openErrorAlert.value = true;
                   // alert('다중계정 사용자가 아닙니다.')
        		}
			});
        })
		
		const verify = () => {
			 store.dispatch("mmb01/fetchMmb01", {
			 	hp_no: userData.value.hp_no,
			  	mem_id:multi_id.value,
			  	sns_join_flag:userData.value.snsJoinFlag,
				sns_ci:userData.value.sns_ci,
			  	proc_cd:'02'
			 }).then(x => {
        	 	if(x.Res_cd === "9999"){
					alert(x.Res_msg)
            		store.commit("SET_USERDATA", {userData: {}});
					store.commit('mhm01/RESET_ALL');
					router.push("/");
        	 	}
        	 	else{
            		store.commit("SET_USERDATA", {userData: {}});
					store.commit('mhm01/RESET_ALL');
					store.commit("app/SET_RESMESSAGE", {res_message: null});
					store.commit("app/SET_RESMESSAGE", {res_message: '연결에 실패했습니다.'});
					openErrorAlert.value = true;
                    //alert('연결에 실패했습니다.')
        	 	}
			 });
			 router.replace('/')
			 return;
		}
		return {
		userList,
		Sns_submit,
		handleOpenSns_submit: () => {
			//console.log(Sns_submit.value)
			Sns_submit.value = true;
		},
		handleCloseSns_submit: () => {
			Sns_submit.value = false;
		},
		verify,
		openErrorAlert,
		handleCloseErrorAlert: () => {
			openErrorAlert.value = false;
		},
		t,  //번역필수 모듈
		  i18n,
		multi_id
		}
    }
}
</script>