<template>
	<section class="layer" style="display:block">
		<div class="layer__dim"></div>
        <!-- [D] 가로/세로 가운데 정렬 레이어 일 경우 layer__wrap--centered 클래스 추가 -->
		<div class="layer__wrap layer__wrap--centered">
			<div class="layer-message">
                <div class="layer-message__top">
                    <h3 class="layer-message__title">
                        {{t('10703')}}
                    </h3>
                    <p class="layer-message__desc">
                        {{mem_id}}
                    </p>
                </div>
                <div class="layer-message__btns">
                    <button type="button" class="btn" @click="handleClose">
                         {{t('10250')}}
                    </button>
                    <button type="button" class="btn skyblue" @click="handleClick">
                        {{t('10704')}}
                    </button>
                </div>
			</div>
		</div>
	</section>
</template> 
<script>
import { onBeforeRouteLeave } from 'vue-router';
import { useI18n } from 'vue-i18n' 
import i18n from "@/i18n"; 
export default {
    props: ["handleClose", "handleClick"],
	setup(props) {
        const { t }= useI18n() 
		onBeforeRouteLeave(() => {
			props.handleClose();
			return false;
		})
        return{
            t,  //번역필수 모듈
		  i18n,
        }
 } 
}
</script>